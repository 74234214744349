<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <div class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
            <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
              <i class="fa fa-chevron-left"></i>
            </span>

            <h3 class="d-inline-block" id="page-title">{{ $t('general.sample_type').toUpperCase() }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">

      <dashboard-box :back="true" back-component-name="management.supplier-company.index" class="px-5 py-4">
        <template v-slot:preview>
          <div class="w-100 row mb-7" v-if="itemProxy">
            <div class="col-12 row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(translation, index) in itemProxy.translations">
                <text-input
                    :title="`${$t('sample_type.name')}(${getLanguageById(translation.language_code).name})`"
                    :model.sync="translation.translations.name"
                    :not-list="true"
                    :max="1"
                    name="company_id"
                ></text-input>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdate"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToSubmit"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, RESET_VALUES, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import {GET_ITEMS} from "@/core/services/store/REST.module";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

export default {
  name: "SampleTypeCreateEdit",
  components: {
    CustomMultiSelect,
    ButtonWithIcon,
    TextInput,
    DashboardBox
  },
  data() {
    return {
      id: this.$route.params.id,
      url: 'api/sample-types/',
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      item: CURRENT_ITEM,
      language: LANGUAGE,
    }),
    isValidToSubmit() {
      if(this.item === null || !('converted' in this.item)) return false;
      let formatted = this.convertTranslationObjectToNormal(Object.assign({}, this.item), 'translations');
      for(let i = 0; i < formatted.translations.length; i++){
        const current = formatted.translations[i].name;
        if(current === '' || current === null) return false;
      }
      return true;
    },
    itemProxy: {
      get() {
        if(this.item == null) return null;
        if(!('converted' in this.item)){
           this.setItem(this.covertToTranslationList(Object.assign({}, this.item), 'translations', ['name']));
        }
        return this.item;
      },
      set(value){
        this.setItem(value);
      }
    }
  },
  methods: {
    ...mapMutations({
      setItem: SET_CURRENT_ITEM,
      resetValue: RESET_VALUES
    }),
    createOrUpdate() {
      let payload = {
        url: this.id == null ? this.url : this.url + this.id,
        id: this.id,
        contents: this.itemProxy,
        redirectRouteName: 'management.sample-type.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
    },
  },
  created() {
    this.resetValue();
    if(!this.id){
      this.setItem(this.covertToTranslationList({company_id: null, translations: null }, 'translations', {'name': null}))
    }
  },
  mounted() {
    if (this.id != null) {
      let payload = {
        url: this.url + this.id,
        id: this.id,
      }
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("general.sample_type"), route: "management.sample-type.index"},
      {title: this.$t(this.id ? "general.edit_sample_type" : "general.create_sample_type")},
    ]);
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
